import axios from '@/libs/axios'

class Position {
  static list(params) {
    return axios.post('admin/positions', params)
  }

  static store(params) {
    return axios.post('admin/positions/store', params)
  }

  static view(id) {
    return axios.post(`admin/positions/view/${id}`)
  }

  static update(params) {
    return axios.post('admin/positions/update', params)
  }

  static destroy(id) {
    return axios.post('admin/positions/delete', { id })
  }
}

export default Position
